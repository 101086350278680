@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anonymous+Pro:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro');
@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900&display=swap');
:root {
    --primary-color:  #e96224;
    --hover-color:  #ff6600;
}



button:focus,
input:focus{
  outline: none;
  box-shadow: none;
}
a,
a:hover{
  text-decoration: none;
}

body{
  font-family: 'Roboto', sans-serif;
}

/*------------------  */
.wheel-spin-box {
    position: relative;
    display: inline-block;
    width: 100%;
    max-width: 100vh;
}
#spinwheel {
    position: relative;
    width: 80%;
    margin: auto;
}
.wheeldotsround {
    position: absolute;
    width: 100%;
    height: 100%;
}
.wheeldotsround{
    position: absolute;
    width: 100%;
    height: 100%;
}
.wheeldotsround .wheeldots{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #fff;
    position: absolute;
    z-index: 1;
}
.wheeldotsround .wheeldots:nth-child(2n+1){
    background: #fff;
}

.wheeldotsround .wheeldots.active-dots{
    background: #ff8400;
}
.wheeldotsround .wheeldots.active-dots:nth-child(2n+1){
    background: #2660a4;
}

.wheeldotsround .wheeldots:nth-child(1){
    left: calc(50% - 4px);
    top: 3px;
    /* fill: tomato */
}
.wheeldotsround .wheeldots:nth-child(2){
    right: 25%;
    top: 7%;
}
.wheeldotsround .wheeldots:nth-child(3){
    right: 7%;
    top: 25%;
}
.wheeldotsround .wheeldots:nth-child(4){
    right: 3px;
    top: 50%;
}
.wheeldotsround .wheeldots:nth-child(5){
    right: 8.2%;
    bottom: 23%;
}
.wheeldotsround .wheeldots:nth-child(6){
    right: 25%;
    bottom: 7%;
}

.wheeldotsround .wheeldots:nth-child(7){
    left: calc(50% - 4px);
    bottom: 3px;
}
.wheeldotsround .wheeldots:nth-child(8){
    left: 25%;
    bottom: 7%;
}
.wheeldotsround .wheeldots:nth-child(9){
    left: 8.2%;
    bottom: 23%;
}
.wheeldotsround .wheeldots:nth-child(10){
    left: 3px;
    top: 50%;
}
.wheeldotsround .wheeldots:nth-child(11){
    left: 7%;
    top: 25%;
}
.wheeldotsround .wheeldots:nth-child(12){
    left: 25%;
    top: 7%;
}

#spinwheel svg{
    width: 100%;
    height: 100%;
    border: 15px solid #222;
    border-radius: 50%;
    background: #222;
}
.chartholder{

}


.wheel-spin-box .chartholder .slice path{
  fill: #808080;
}

.wheel-spin-box .chartholder .slice:nth-child(2n+1) path{
    fill: #0f0f0f;
}
.wheel-spin-box .chartholder .slice:nth-child(1) path{
    fill: #f7eb39;
}


.wheel-spin-arrow {
    position: relative;
    margin-top: -35px;
    text-align: center;
    z-index: 1;
}
.wheel-spin-arrow svg {
    max-width: 65px;
}
.spin-click-button {
    background-color: #000;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    border: none;
    padding: 14px 35px;
    border-radius: 15px;
}

/* ---- tsparticles container ---- */

.winIndicator {
  fill: #fff;
}